  @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


a:hover{
text-decoration: none !important;
}

.home-banner-div1 {
  background-image: url(../Image/Rectangle.png);
  background-color: #0b232b;
  min-height: 100vh;
  padding-top: 80px;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  /* background: linear-gradient(to bottom, #0b232b 78%, white 22%); */
}

.home-banner-color-svg1 {
  width: 200px;
  height: 200px;
  flex-shrink: 0;
  background: rgba(246, 159, 39, 0.52);
  filter: blur(127px);
  position: absolute;
  top: 100px;
  left: -45px;
}

.home-banner-color-svg2 {
  width: 160px;
  height: 160px;
  flex-shrink: 0;
  background: rgba(246, 159, 39, 0.6);
  filter: blur(127px);
  position: absolute;
  bottom: 35%;
  right: 100px;
}



.home-banner-text-div1{

}

.home-banner-text-div1 h4{
color: #FFF;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: 28px; /* 150% */
}


.home-banner-text-div1 h2{
color: #F1F1F1;
font-family: Helvetica;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 54px; /* 103.846% */
text-transform: uppercase;
margin-top: 20px;
}

.home-banner-text-div1 h1{
color: #FFF;
font-family: Helvetica;
font-size: 60px;
font-style: normal;
font-weight: 700;
line-height: 68px;
text-transform: uppercase;
margin-top: 20px;
}


.home-banner-text-div1 h1 span{
background: linear-gradient(90deg, #FFAD40 1.71%, #FFD7A2 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.home-banner-text-div1 h3{
color: #FFF;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 150% */
margin-top: 14px;
}


.home-banner-btn-div1{
    display: flex;
    align-items: center;
    column-gap: 45px;
    margin-top: 30px;
}

.home-banner-btn11{
  min-width: 100px;
    display: flex;
    align-items: center;
    padding:7px ;
    padding-right: 30px !important;
    column-gap: 24px;
    border-radius: 60px;
border: 1px solid #F59C23;
background: linear-gradient(90deg, #F59C23 0.29%, #FFBB5D 99.77%);
color: #000;
font-family: Helvetica;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 23.212px; /* 128.954% */
flex-shrink: 0;
}

.home-banner-btn11 span{
font-weight: 700;
margin-left: -18px;
}


.home-btn-avaible-div1 p{
color: #FFF;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 30px; /* 250% */
display: flex;
align-items: center;
column-gap: 10px;
margin-bottom: 0px !important;
margin-top: 0px !important;
} 

.home-btn-avaible-div1 p div{
background-color: #E73939;
box-shadow: 0px 0px 10px rgba(255, 49, 49, 5);
width: 12px;
height: 12px;
border-radius: 20px;
}


.home-banner-contract-text1{
  color: #FFF;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20.518px; /* 146.555% */
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-bottom: 0px !important;
  margin-top: 26px;
  margin-left: 6px;
}


.banner-pic1{
  margin-top: -22px;
}

.banner-pic-2{
    position: absolute;
    bottom: 0px;
    left: 64%;
    transform: translateX(-50%);
    z-index: 9999px;
}

@media screen and (max-width: 1765px) {
.banner-pic-2{
    position: absolute;
    bottom: 00px;
    left: 69%;
    z-index: 9999px;

    transform: translateX(-50%);
}

}

@media screen and (max-width: 1400px) {
.banner-pic-2{
    position: absolute;
    bottom: 00px;
    left: 72%;
    transform: translateX(-50%);
    z-index: 9999px;

}


}


@media screen and (max-width: 1300px) {
.banner-pic-2{
    position: absolute;
    bottom: 00px;
    left: 75%;
    transform: translateX(-50%);
}

}
































.home-amazon-sales-div1{
background: #F9F9F9;
padding-top: 50px;
padding-bottom: 65px;
}

.home-amazon-sales-textdiv11 p{
color: #1A1A1A;
font-family: Poppins;
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: 25px; /* 166.667% */
}

.home-amazon-sales-textdiv11 p .span1{
color: #EB9523 !important;
font-family: Poppins;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: 25px; /* 166.667% */
}


.home-amazon-sales-textdiv11 p span:last-child{
color: #1A1A1A;
font-family: Poppins;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: 25px;
}

.home-amazon-sales-textdiv22 div{
display: flex;
align-items: center;
justify-content: center;
column-gap: 10px;
}

.home-amazon-sales-textdiv22 div h1{
color: #0B232B;
text-align: center;
font-family: Helvetica;
font-size: 60px;
font-style: normal;
font-weight: 700;
line-height: 54px; /* 71.518% */
margin-bottom: 0px !important;
}

.home-amazon-sales-textdiv22 p{
    color: #1A1A1A;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 30px;
text-align: center;
margin-top: 14px;
font-style: italic;
}

.home-amazon-sales-textdiv22 p span{
color: #EB9523;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 166.667% */
text-align: center;
font-style: italic;
}
























.home-listing-card-div1{
background: #F9F9F9;
padding-bottom: 65px;
padding-left: 15px;
padding-right: 15px;
}

.home-listing-card-div22 {
position: relative;
border-radius: 10px;
background: #0B232B;
padding: 32px 38px;
}

.home-listing-card-div22 h1{
  color: #FFF;
font-family: Helvetica;
font-size: 27px;
font-style: normal;
font-weight: 700;
line-height: 36px;
text-transform: uppercase;
}

.home-listing-card-div22 img{
  margin-top: 34px;
  display: flex;
  justify-content: center;
  min-width: 100% !important;
}

.home-listing-img0 {
    /* min-width: 295px !important; */
    max-width: 105%;

}

.home-listing-card-div33{
  width: 40px;
height: 40px;
flex-shrink: 0;
border-radius:  5px;
background: #FDB34E;
display: flex;
align-items: center;
justify-content: center;
position: absolute;
top: 10px;
right: 10px;
}


























.design-slide-maindiv1{
  margin-top: 26px;
}

.design-slide-pic-div1 img{
  padding-left:13px;
  padding-right:13px;

}

.design-slide2-maindiv1{
  margin-top: 26px;
  margin-bottom: 26px;
}

.design-slide2-pic-div1{
  padding-left:13px;
  padding-right:13px;
  transform: translateX(200px);

}


























.home-task-point-div1{
  background: #FFF7ED;
  padding-top: 70px;
  padding-bottom: 70px;
}


.home-task-point-cardimg1{
  height: 66px;
  display: flex;
  align-items: end;
  justify-content: center;
}

.home-task-point-cardtext1{
text-align: center;
color: #0D1016;
font-family: Helvetica;
font-size: 22.5px;
font-style: normal;
font-weight: 700;
line-height: 29px; /* 128.889% */
letter-spacing: -0.86px;
margin-top: 26px;
}

.home-task-point-cardtext2{
text-align: center;
color: #666;
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 160% */
margin-top: 6px;
}
































.home-service-div1{
   background-image: url(../Image/Rectangle.png);
   padding-bottom: 100px;
  padding-top: 55px;
  position: relative;
  background: #0b232b;

}

.home-build-text1 {
  color: #ffd49c;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
  margin-right: 9px;
}

.home-build-text2 {
  color: #ffad40;
  font-family: Inter;
  font-size: 11px;
  /* font-style: italic; */
  font-weight: 600;
  line-height: 15.609px; /* 141.902% */
  text-decoration: underline;
  margin-bottom: 0px;
  margin-left: 5px;
}

.home-service-text11{
background: linear-gradient(90deg, #FFAD40 1.71%, #FFD7A2 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
text-align: center;
font-family: Helvetica;
font-size: 52px;
font-style: normal;
font-weight: 700;
line-height: 66px; /* 138.462% */
text-transform: uppercase;
margin-top: 18px;
}

.home-service-text22{
color: #FFF;
text-align: center;
font-family: Helvetica;
font-size: 34px;
font-style: normal;
font-weight: 400;
line-height: 42px; /* 232.258% */
text-transform: uppercase;
}

.home-service-text22 span{
color: #FFF;
font-family: Helvetica;
font-size: 34px;
font-style: normal;
font-weight: 700;
line-height: 42px;
text-transform: uppercase;
}

.home-service-img-div11 {
  margin-top: 40px;
}

.home-service-text44{
color: #FFF;
font-family: Poppins;
font-size: 28px;
font-style: normal;
font-weight: 300;
line-height: 30px; /* 107.143% */
margin-top: 40px;
text-align: center;
}

.home-service-text44 span{
color: #FFF;
font-family: Poppins;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: 30px; /* 107.143% */
}

.home-service-text55{
color: #FFD8A3;
text-align: center;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 300;
line-height: 34px; /* 125% */
margin-top: 22px;
}



.home-servicesbanner-btn11{
  min-width: 320px;
    display: inline-flex;
    align-items: center;
    /* justify-content: center; */
    padding:7px ;
    padding-right: 40px !important;
    column-gap: 24px;
    border-radius: 60px;
border: 1px solid #F59C23;
background: linear-gradient(90deg, #F59C23 0.29%, #FFBB5D 99.77%);
color: #000;
font-family: Helvetica;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 23.212px; /* 128.954% */
flex-shrink: 0;
position: relative;
max-height: 80px;
margin-top: 35px;
}

.home-servicesbanner-btn11 img {
    width: 70px;
    
}

.home-servicesbanner-btn11 span{
font-weight: 700;
margin-left: -17px;

}






.home-plnt-pic1{
  width: 100% !important;
}


































.home-review-div1{
    padding-top: 0px;
    padding-bottom: 90px;
}

.home-review-text-div1{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.home-review-text-div1 div h2{
color: #0D1016;
text-align: center;
font-family: Helvetica;
font-size: 42px;
font-style: normal;
font-weight: 700;
line-height: 58px; /* 141.565% */
}

.home-review-text-div1 div h2 span{
color: #F7B53C;
}


.home-review-card-rowdiv1{
    margin-top: 55px;
    min-height: 100%;
}


.home-review-col-div1{
min-height: 100%;
}

.home-review-col-div2{
border: 1.064px solid #DADADA;
background: #FFF;
padding: 50px;
min-height: 100%;
}

.home-review-col-div1 div h2{
color: #000;
font-family: Poppins;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: 34px; /* 111.111% */
text-transform: uppercase;
}

.home-review-col-div1 div h2 span{
color: #F7B53C;
}

.home-review-col-div1 div p{
color: #313131;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 154.893% */
margin-top: 12px;
}

.home-reviews-img-mainsdiv11{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-reviews-img-div1{
display: flex;
align-items: center;
column-gap: 13px;
}

.home-reviews-img-div1 div img+p{
color: #000;
text-align: center;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 187.203% */
margin-top: 0px;
margin-bottom: 6.5px;
}

.home-review-col-div1 div h3{
    color: #1E1E1E;
font-family: "Bebas Neue";
font-size: 36.966px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin-top: 29px;
}


.swiper {
    overflow: visible !important;
    overflow-x: clip !important;
   
}

.swiper .col-12{
margin-top: 50px  !important;
   
}


.swiper-button-next{
    right: 0px !important;
    top: -70px !important;
    width: 100px !important;
    height: 100px !important;

}

.swiper-button-next:after{
    /* content:'prev' !important; */
    content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" ><path fill-rule="evenodd" clip-rule="evenodd" d="M14.8344 16.8881L19.3824 12.3401C19.5776 12.1448 19.5776 11.8283 19.3824 11.633L14.8524 7.10299C14.6571 6.90773 14.3405 6.90773 14.1452 7.10299C13.95 7.29825 13.95 7.61483 14.1452 7.8101L17.8248 11.4896C17.8061 11.4876 17.7871 11.4865 17.7679 11.4866L4.96965 11.5135C4.69351 11.5141 4.47012 11.7385 4.4707 12.0146C4.47129 12.2907 4.69562 12.5141 4.97176 12.5135L17.77 12.4866C17.7885 12.4865 17.8068 12.4855 17.8248 12.4835L14.1272 16.181C13.932 16.3763 13.932 16.6928 14.1272 16.8881C14.3225 17.0834 14.6391 17.0834 14.8344 16.8881ZM18.2659 12.0423L18.3217 11.9866L18.2659 11.9308C18.2679 11.9487 18.2689 11.967 18.269 11.9855C18.269 12.0047 18.268 12.0237 18.2659 12.0423Z" fill="%23242533" /></svg>') !important;
  display: flex;
padding: 12px 24px;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 70px;
border: 1px solid #000;
background: #FFE1AB;
line-height: 1px !important;
  }


.swiper-button-prev{
    right: 90px !important;
    top: -70px !important;
    width: 100px !important;
    height: 100px !important;

}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: auto !important;
    right: auto;
}

.swiper-button-prev:after{
    /* content:'prev' !important; */
    content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" ><path fill-rule="evenodd" clip-rule="evenodd" d="M9.16515 7.11202L4.61715 11.66C4.42189 11.8553 4.42189 12.1719 4.61715 12.3671L9.14716 16.8971C9.34242 17.0924 9.659 17.0924 9.85426 16.8971C10.0495 16.7019 10.0495 16.3853 9.85426 16.19L6.17473 12.5105C6.19339 12.5126 6.21236 12.5136 6.23158 12.5136L19.0289 12.4866C19.3051 12.486 19.5285 12.2617 19.5279 11.9855C19.5273 11.7094 19.303 11.486 19.0268 11.4866L6.22948 11.5136C6.21097 11.5136 6.19271 11.5147 6.17473 11.5167L9.87226 7.81913C10.0675 7.62387 10.0675 7.30728 9.87226 7.11202C9.677 6.91676 9.36042 6.91676 9.16515 7.11202ZM5.73361 11.9578L5.67781 12.0136L5.73361 12.0694C5.73161 12.0514 5.73057 12.0331 5.73053 12.0146C5.73049 11.9954 5.73153 11.9764 5.73361 11.9578Z" fill="%23242533" /></svg>') !important;
      display: flex;
padding: 12px 24px;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 70px;
border: 1px solid #000;
/* background: #FFE1AB; */
background: #FFF6E7;
line-height: 1px !important;
  }






































.home-drivesales-div1{
overflow: hidden;
}


.home-drivesales-col1-div2{
  padding-left: 100px;
  background: #101118;
  padding-top: 85px;
}

.home-drivesales-col1-div2 div h1{
  color: #FFF;
font-family: Helvetica;
font-size: 42px;
font-style: normal;
font-weight: 700;
line-height: 56px;
}


.home-drivesales-col1-div2 div h1 span{
color: #FEB756;
text-align: center;
font-family: Helvetica;
font-size: 42px;
font-style: normal;
font-weight: 700;
line-height: 56px; /* 141.565% */
}

.home-drivesales-col1-div2 div p{
color: #EAEAEA;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 197.416% */
}

.drive-ranking-img {
    min-width: 100%;
    /* transform: translate(-20px); */
    margin-left: -40px;
}


@media screen and (max-width: 1450px) {
.drive-ranking-img {
    min-width: 100%;
    /* transform: translate(-20px); */
    margin-left: -80px;
}

}







.home-drivesales-col2-div3{
  background: #F8F8F8;
  display: flex;
  padding-top: 85px;
  justify-content: center;
  text-align: center;
}

.home-drivesales-col2-div3 div h1{
  color: #000;
font-family: Helvetica;
font-size: 42px;
font-style: normal;
font-weight: 700;
line-height: 56px;
}


.home-drivesales-col2-div3 div h1 span{
color: #FEB756;
text-align: center;
font-family: Helvetica;
font-size: 42px;
font-style: normal;
font-weight: 700;
line-height: 56px; /* 141.565% */
}

.home-drivesales-col2-div3 div p{
  color: #171717;
text-align: center;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 197.416% */
}


.home-drivesales-col2-div3 img{
   margin-top: 45px;
  min-width: 68% !important;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}


/* 
@media screen and (max-width: 2200px) {
.home-drivesales-col2-div3 img{
  margin-top: 45px;
  min-width: 500px !important;
}
}

@media screen and (max-width: 2100px) {
.home-drivesales-col2-div3 img{
  margin-top: 45px;
  min-width: 500px !important;
}
} */




















/* 

.home-reviews-div1{
padding-top: 43px;
padding-bottom: 40px;
}

.home-reviews-card-maindiv1{
padding: 30px 54px;
}

.home-reviews-card-div2{
padding: 45px 60px;
padding-top: 30px !important;
border-radius: 18.72px;
border: 1.326px solid #A0A0A0;
background: #000;
box-shadow: 12.48px 12.48px 17.472px 0px rgba(0, 0, 0, 0.15);
backdrop-filter: blur(102.33773803710938px);
position: relative;
}

.review-svg1{
  position: absolute;
  top: 30px;
  right: 40px;
}


.home-reviews-card-div2 h1{
color: #FFF;
font-family: Poppins;
font-size: 27px;
font-style: normal;
font-weight: 600;
line-height: 35px; 
text-transform: uppercase;
margin-top: 18px;
}

.home-reviews-card-div2 h1+p{
  color: #E0E0E0;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 300 !important;
line-height: 26.208px; 
margin-top: 10px;
}


 */





























.home-j2m-div1{
  background: #101118;
padding-top: 110px;
padding-bottom: 0px;
/* position: fixed; */
/* top: 0px; */
/* min-height: 100vh; */
display: flex;
align-items: center;
}

.home-actj2m-div1{
  background: #101118;
padding-top: 110px;
padding-bottom: 0px;
/* min-height: 100vh; */
display: flex;
align-items: center;
position: sticky;
top: 0px;
z-index: 9999999999999999;
}

.home-j2m-col2-text11{
  color: #FFF;
font-family: Helvetica;
font-size: 52px;
font-style: normal;
font-weight: 700;
line-height: 65.513px; /* 125.986% */
text-transform: uppercase;
margin-top: 10px;
}

.home-research-text-div1{
  display: flex;
  column-gap: 35px;
  margin-top: 50px;
}


.home-research-text-div2 p{
  display: flex;
width: 50px;
height: 50px;
justify-content: center;
align-items: center;
flex-shrink: 0;
border-radius: 100px;
border: 5px solid #CD7700;
background: #F6A12C;
box-shadow: 0px 0px 0px 10px #CD7700, 0px 0px 0px 11.5px #F6A12C;
color: #101118;
text-align: center;
font-family: Helvetica;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 33.6px; /* 140% */
}


.home-research-text-div3 h1{
color: #F6A12C;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 300;
line-height: 30px; /* 125% */
}

.home-research-text-div3 p{
color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 25px; /* 156.25% */
padding-right: 70px;
margin-top: 16px;
}


.home-designsec-text-div2 {
border-radius: 100px;
background: var(--White, #FFF);
width: 16px;
height: 16px;
flex-shrink: 0;
margin-left: 20px;
}

.home-designsec-text-div2 p{
  display: none;
}

.home-designsec-text-div3 h1{
  color: #FFF;
font-family: Poppins;
font-size: 24px;
font-style: normal;
font-weight: 300;
line-height: 30px; /* 125% */
margin-bottom: 0px !important;
}






























.plant-svg{
  background: #101118;
}

.home-plant-pic2{
  /* min-width: 100%; */
width:  100%;
}



.home-last-sec-div1 {
  padding-top: 80px;
  padding-bottom: 120px;
  background: #0B232B;
}

.home-roots-textdiv1 h1{
color: #FFF;
text-align: center;
font-family: Helvetica;
font-size: 74px;
font-style: normal;
font-weight: 700;
line-height: 72px; /* 97.297% */
}


.home-roots-textdiv1 h1 span{
color: #FFAB3E;
}

.home-roots-textdiv1 p{
color: #F0F0F0;
text-align: center;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 150% */
/* margin-bottom: 130px; */
margin-top: 20px;
}

.home-last-sec-rowdiv1 {
  padding-top: 160px;
  background-color: #0B232B;
}

.home-last-sec-rowdiv1 .col-lg-6.col-12{
  padding-left: 30px;
  padding-right: 30px;
}

.home-last-sec-text1 {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 130.755% */
  letter-spacing: 0.577px;
 border-radius: 20px;
border: 1.2px solid #E37F00;
background: #FFC981;
 display: inline-flex;
  padding: 5px 5px;
  padding-left: 20px;
  column-gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}

.home-last-sec-text1 div{
 color: #000;
text-align: center;
font-family: Helvetica;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 33.6px; /* 210% */
width:  32px;
height: 32px;
display: flex;
align-items: center;
justify-content: center;
flex-shrink: 0;
background-color: #FFE9CC;
stroke-width: 1px;
stroke: #E37F00;
border-radius: 50px;
}

.home-last-sec-text2 {
 color: #FFF;
font-family: Helvetica;
font-size: 37px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-top: 22px;
}

.home-last-sec-para2{
  color: #F0F0F0;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 150% */
margin-top: 22px;
}

.home-last-sec-ultext {
  margin-top: 22px;
  padding-left: 0px;
}

.home-last-sec-ultext li {
  color: #FFC981;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 150% */
background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cg clip-path='url(%23clip0_1466_9200)'%3E%3Cpath d='M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z' fill='%23FFC981'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.39413 11.8849L13.6111 6.66797L14.6587 7.7155L8.39413 13.98L5 10.5859L6.04757 9.53837L8.39413 11.8849Z' fill='%2317181D'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1466_9200'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
background-repeat: no-repeat;
padding-left: 35px;
    list-style: none;
    background-position-y: 3.2px;
    margin-top: 6px

}

.home-last-sec-ultext li::marker {
 margin-top: 10px;
}


.home-last-sec-ultext li span{
  color: #D7D7D7;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;
position: relative;
}


.home-last-sec-rowdiv1 .col-lg-6.col-12 div img{
  margin-top: 4px;
}

.last-sec-color-svg1{
background-color: rgba(53, 123, 223, 0.54);
filter: blur(127px);
width: 130px;
height: 130px;
flex-shrink: 0;
position: absolute;
top: 0px;
right: -30px;
}

.last-sec-color-svg2{
background-color: rgba(246, 159, 39, 0.54);
filter: blur(127px);
width: 130px;
height: 130px;
flex-shrink: 0;
position: absolute;
bottom: 0px;
left: -30px;
}
























.real-client-text1 h1{
color: #FFF;
text-align: center;
font-family: Helvetica;
font-size: 64px;
font-style: normal;
font-weight: 700;
line-height: 64px; /* 100% */
}

.real-client-row-div1{
margin-top: 70px;
}

.real-client-col-div11{
border-radius: 15px;
/* border: 1.062px solid #A0A0A0; */
padding: 26px;
background-image: url(../Image/Group\ 1000008533.png);
background-repeat: no-repeat;
    background-size: cover;

}

.real-client-week-div11{
display: flex;
justify-content: space-between;
}

.real-client-week-div11 div h2{
color: #0A2027;
font-family: Helvetica;
font-size: 33px;
font-style: normal;
font-weight: 700;
line-height: 26.295px; /* 79.682% */
}

.real-client-week-div11 div ul{
padding-left: 26px !important;
}

.real-client-week-div11 div ul li{
color: #0A2027;
font-family: Helvetica;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 22.178px; /* 138.61% */
}


.home-table .thead-dark th {
    color: #fff;
    background-color: black;
    border-color: none;
    color: #F5E9DF;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-align: center;
}

th.date-table{
  padding-right: 100px;
  text-align: left !important;
}


.table-striped tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .2) !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent !important;
}

.home-table tbody tr td{
  text-align: center;
  color: #000;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

td.home-table-tr11{
  text-align: left !important;
}

.home-real-client-para112{
  color: #F4F4F4;
font-family: Helvetica;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 22.178px; /* 138.61% */
margin-top: 22px;
text-align: center;
}




































.audit-design-div1 {
  padding-top: 35px;
  padding-bottom: 80px;
}

.audit-design-text1 {
  color: #000;
  text-align: center;
  font-family: Helvetica;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 62px; /* 118.519% */
}

.audit-design-text2 {
  color: #171717;
  text-align: center;
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 130.769% */
  margin-top: 16px;
}

.audit-design-icondiv11 {
  margin-top: 52px;
}

.audit-design-icondiv3 {
  display: flex;
  justify-content: center;
  min-height: 120px;
  align-items: center;
}

.audit-design-text3 {
  color: #171717;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
  padding-left: 15px;
  padding-right: 15px;
}









































.home-faqs-div1{
padding-top: 80px;
padding-bottom: 80px;
background: #FFF7ED;
}

.home-faqs-text{
  color: #0D1016;
font-family: Helvetica;
font-size: 38px;
font-style: normal;
font-weight: 700;
line-height: 29px; /* 76.316% */
letter-spacing: -0.9px;
text-align: center;
}


.home-faq-div2 {
  margin-top: 40px;
}

.faq-div1 {
  border-radius: 5px;
border: 1px solid #FFAB3E;
background: #FFE8BD;
box-shadow: 0px 4px 14px 0px #FFE8CB;
  padding: 22px 20px;
}

.faq-question-div1 {
  justify-content: space-between;
}

.faq-text1 {
color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 23px; /* 145.073% */
margin-bottom: 0px !important;
}

.faq-text1:hover {
  cursor: pointer;
}

.faq-question-div1 svg {
  cursor: pointer;
}

.faq-answer-div1 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.faq-text2 {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}






















.home-zoom-div1{
  padding-top: 60px;
  padding-bottom: 60px;
}

.home-zoom-text11{
padding: 26px 30px;
border-radius: 5px;
border: 1px solid #D7D7D7;
background: #FFF;
box-shadow: 0px 14px 14px 0px #F0F0F0;
display: inline-flex;
align-items: center;
column-gap: 20px;
max-width: 360px;
}

.home-zoom-text11 img{

}

.home-zoom-text11 p{
color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 125% */
margin-bottom: 0px !important;
}

.home-zoom-text22{
border-radius: 5px;
border: 1px solid #FFAB3E;
background: #FFF9F1;
box-shadow: 0px 14px 14px 0px #F0F0F0;
display: inline-flex;
align-items: center;
column-gap: 20px;
margin-left: 30px;
margin-top: 20px;
padding: 26px;
max-width: 370px;

}

.home-zoom-text22 img{

}

.home-zoom-text22 p{
  color: #000;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 125% */
margin-bottom: 0px !important;
}

.home-zoom-text33 {
  text-align: center;
  margin-top: 35px;
}

.home-zoom-text33 h1{
color: #000;
font-family: Helvetica;
font-size: 38px;
font-style: normal;
font-weight: 700;
line-height: 64px; /* 160% */
margin-top: 26px;
}

.home-zoom-text33 p{
color: #000;
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 300;
line-height: 30px; /* 150% */
}












@media screen and (max-width: 576px) {

  
.home-banner-div1 {
  min-height: 100vh;
  padding-top: 125px;
}

.home-banner-color-svg1 {
  width: 120px;
  height: 120px;
  top: 100px;
  left: -45px;
}

.home-banner-color-svg2 {
  width: 100px;
  height: 100px;
  bottom: 35%;
  right: 100px;
}

.home-banner-text-div1 h4{
font-size: 16px;
line-height: 28px; /* 150% */
padding-left: 30px;
padding-right: 30px;
text-align: center;
}

.home-banner-text-div1 h2{
font-size: 32px;
line-height: 40px; /* 103.846% */
margin-top: 20px;
text-align: center;
}

.home-banner-text-div1 h1{
font-size: 38px;
line-height: 48px;
margin-top: 20px;
text-align: center;
}


.home-banner-text-div1 h3{
font-size: 15px;
line-height: 24px; /* 150% */
margin-top: 16px;
text-align: center;
padding-left: 25px;
padding-right: 25px;
}

.home-banner-btn-div1{
    display: block;
    margin-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.home-banner-btn11{
  min-width: 100%;
    padding:16px 10px ;
    padding-right: 10px !important;
    column-gap: 30px;
    font-size: 14.5px;
    line-height: 24px; /* 128.954% */
    text-align: center;
    min-height: 40px;
    justify-content: center;
}

.home-banner-btn11 svg{
  display: none;
  width: 50px;
  height: 50px;
}

.home-banner-btn11 span{
font-weight: 700;
margin-left: -24px;
}

.home-btn-avaible-div1 p{
font-size: 12px;
line-height: 30px; 
column-gap: 10px;
margin-top:20px !important;
justify-content: center;
} 

.home-banner-contract-text1{
font-size: 14px;
line-height: 20.518px; 
  justify-content: center;
  column-gap: 12px;
  margin-top: 10px;
  margin-left: 0px;
}

.banner-pic1{
  margin-top: 25px;
  margin-bottom: -30px;
}

.banner-pic-2{
    left: 50%;
    transform: translateX(-50%);
    max-width: 48%;
}

































.home-amazon-sales-div1{
background: #F9F9F9;
padding-top: 52px;
padding-bottom: 70px;
}

.home-amazon-sales-textdiv11 p{
font-size: 18px;
line-height: 25px; /* 166.667% */
text-align: center;
}

.home-amazon-sales-textdiv11 p .span1{
font-size: 18px;
line-height: 25px; /* 166.667% */
}


.home-amazon-sales-textdiv11 p span:last-child{
font-size: 18px;
line-height: 25px;
}

.home-amazon-sales-textdiv22{
margin-top: 36px;
}

.home-amazon-sales-textdiv22 div{
display: flex;
align-items: center;
justify-content: center;
column-gap: 20px;
}

.home-amazon-sales-textdiv22 div h1{
font-size: 56px;
line-height: 54px; /* 71.518% */
margin-bottom: 0px !important;
}

.home-amazon-sales-textdiv22 p{
font-size: 15px;
line-height: 30px;
margin-top: 6px;
}

.home-amazon-sales-textdiv22 p span{
font-size: 15px;
line-height: 30px; /* 166.667% */
}

.for-mobile-reponsive-sales-img{
  margin-right: -12px;
}






















.home-listing-card-div1{
background: #F9F9F9;
padding-bottom: 20px;
padding-left: 20px;
padding-right: 20px;
}

.home-listing-card-div22 {
padding: 30px 28px;
margin-top: 20px;
}

.home-listing-card-div22 h1{
font-size: 26px;
line-height: 34px;
}

.home-listing-card-div22 img{
  margin-top: 30px;
}


.home-listing-img0 {
    max-width: 106% !important;

}



























.design-slide-maindiv1{
  margin-top: 20px;
}

.design-slide-pic-div1 img{
  padding-left:10px;
  padding-right:10px;
  width: 300px;
}

.design-slide2-maindiv1{
  margin-top: 20px;
  margin-bottom: 20px;
}


.design-slide2-pic-div1{
   padding-left:10px;
  padding-right:10px;
  transform: translateX(-170px);

}

.design-slide2-pic-div1 img{
   padding-left:0px;
  padding-right:0px;
  width: 300px;

}



























.home-task-point-div1{
  padding-top: 20px;
  padding-bottom: 40px;
}


.home-task-point-cardimg1{
  height: 50px;
  display: flex;
  align-items: end;
  justify-content: center;
  margin-top: 28px;
}

.home-task-point-cardtext1{
font-size: 22px;
line-height: 27px; /* 128.889% */
margin-top: 16px;
}

.home-task-point-cardtext2{
font-size: 14px;
line-height: 22px; /* 160% */
margin-top: 6px;
padding: 0px 20px;
}





























.home-service-div1{
   padding-bottom: 60px;
  padding-top: 55px;
}


.home-service-text11{
font-size: 33px;
line-height: 42px; /* 138.462% */
margin-top: 18px;
}

.home-service-text22{
font-size: 22px;
line-height: 34px; /* 232.258% */
margin-top: 14px;
}

.home-service-text22 span{
font-size: 22px;
line-height: 35px;
text-transform: uppercase;
}

.home-service-img-div11 {
  margin-top: 30px;
}

.home-service-img-div11 .position-absolute{
  width: 20%;
}

.home-service-text44{
font-size: 22px;
line-height: 30px; /* 107.143% */
margin-top: 40px;
text-align: center;
}

.home-service-text44 span{
font-size: 22px;
line-height: 30px; /* 107.143% */
}

.home-service-text55{
font-size: 18px;
line-height: 28px; /* 125% */
margin-top: 22px;
}


.home-servicesbanner-btn11{
  min-width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding:7px ;
    padding-right: 30px !important;
font-size: 16px;
max-height: 80px;
margin-top: 35px;
column-gap: 18px;
}

.home-servicesbanner-btn11 img {
    width: 50px;
}

.home-servicesbanner-btn11 span{
font-weight: 700;
margin-left: -12px;

}






/* 
.home-plnt-pic1{
  width: 100% !important;
} */








































.home-review-div1{
    padding-top: 10px;
    padding-bottom: 60px;
}

.home-review-text-div1{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.home-review-text-div1 div h2{
font-size: 36px;
line-height: 40px; /* 141.565% */
text-align: center;
}

.home-review-col-div2{
padding: 30px;
padding-right: 26px;
min-height: 100%;
}

.home-review-col-div1 div h2{
font-size: 24px;
line-height: 32px; /* 111.111% */
}


.home-review-col-div1 div p{
font-size: 16px;
line-height: 24px; /* 154.893% */
margin-top: 12px;
}

.home-reviews-img-mainsdiv11{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.home-reviews-img-div1{
display: flex;
align-items: center;
column-gap: 0px;
}


.home-reviews-img-div1 div:first-child img{
width: 85% !important;
}


.home-reviews-img-div1 div img{
width: 92%;
}


.home-review-logo-img img{
width: 60%;
margin-left: 25px;
}

.home-reviews-img-div1 div img+p{
font-size: 12px;
line-height: 18px; /* 187.203% */
margin-top: 0px;
margin-bottom: 7px;
}

.swiper {
    overflow: visible !important;
    overflow-x: clip !important;
    margin-top: 52px;
}

.swiper .col-12{
  padding: 0px !important;
}

.swiper-button-next{
    /* right: 0px !important; */
    left: 50% !important;
    transform: translateX(-50%);
    top: -40px !important;
}

.swiper-button-next:after{
    margin-left: 92px;
    
  }

.swiper-button-prev{
    /* right: 90px !important; */
    top: -40px !important;
    left: 50% !important;
    transform: translateX(-50%);
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
    /* left: auto ; */
    /* right: auto; */
}

.swiper-button-prev:after{    
margin-right: 92px;
  }































  



.home-drivesales-div1{
overflow: hidden;
}


.home-drivesales-col1-div2{
  padding-left: 15px;
  background: #101118;
  padding-top: 40px;
}

.home-drivesales-col1-div2 div h1{
font-size: 30px;
line-height: 42px;
text-align: center;
padding-right: 30px;
padding-left: 30px;
}

.home-drivesales-col1-div2 div h1 span{
font-size: 30px;
line-height: 42px; /* 141.565% */
}

.home-drivesales-col1-div2 div img{
width: 100%;
margin-left: 10px;
margin-top: 8px;
}

.home-drivesales-col1-div2 div p{
font-size: 15px;
line-height: 26px; /* 197.416% */
padding-right: 30px;
padding-left: 30px;
text-align: center;
}







.home-drivesales-col2-div3{
  display: flex;
  padding-top: 40px;
  justify-content: center;
  text-align: center;
}

.home-drivesales-col2-div3 div h1{
font-size: 30px;
line-height: 42px;
padding-left: 30px;
padding-right: 30px;
}


.home-drivesales-col2-div3 div h1 span{
font-size: 30px;
line-height: 42px; /* 141.565% */
}

.home-drivesales-col2-div3 div p{
font-size: 15px;
line-height: 26px; /* 197.416% */
padding-left: 40px;
padding-right: 40px;
}


.home-drivesales-col2-div3 img{
  margin-top: 20px;
  max-width: 86% !important;
  position: relative;
    left: 0%;
    transform: translateX(0%);
}












































.home-j2m-div1{
  background: #101118;
padding-top: 70px;
padding-bottom: 30px;
}

.home-j2m-col2-text11{
font-size: 32.5px;
line-height: 42px; /* 125.986% */
margin-top: 65px;
}

.home-research-text-div1{
  display: flex;
  column-gap: 30px;
  margin-top: 40px;
}

.home-research-text-div2 p{
  display: flex;
width: 40px;
height: 40px;
box-shadow: 0px 0px 0px 7px #CD7700, 0px 0px 0px 9.2px #F6A12C;
font-size: 20px;
line-height: 33.6px; /* 140% */
}

.home-research-text-div3 p{
font-size: 14px;
line-height: 25px; /* 156.25% */
padding-right: 25px;
margin-top: 16px;
margin-bottom: 0px !important;
}


.home-designsec-text-div2 {
border-radius: 100px;
background: var(--White, #FFF);
width: 16px;
height: 16px;
flex-shrink: 0;
margin-left: 20px;
}

.home-designsec-text-div3 h1{
font-size: 22px;
line-height: 30px; /* 125% */
margin-bottom: 0px !important;
}
































.home-last-sec-div1 {
  padding-top: 60px;
  padding-bottom: 70px;
}

.home-roots-textdiv1 h1{
font-size: 38px;
line-height: 46px; /* 97.297% */
}


.home-roots-textdiv1 p{
font-size: 16px;
line-height: 25px; /* 150% */
margin-bottom: 0px;
margin-top: 14px;
}


.home-last-sec-rowdiv1 {
  /* row-gap: 70px; */
  padding-top: 80px;
}

.home-last-sec-rowdiv1 .col-lg-6.col-12{
  padding-left: 16px;
  padding-right: 16px;
}

.home-last-sec-text1 {
  font-size: 12px;
  line-height: 20px; /* 130.755% */
  padding: 5px 5px;
  padding-left: 15px;
  column-gap: 20px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
}

.home-last-sec-text1 div{
 color: #000;
text-align: center;
font-family: Helvetica;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 33.6px; /* 210% */
width:  32px;
height: 32px;
display: flex;
align-items: center;
justify-content: center;
flex-shrink: 0;
background-color: #FFE9CC;
stroke-width: 1px;
stroke: #E37F00;
border-radius: 50px;
}

.home-last-sec-text2 {
font-size: 28px;
line-height: 36px;
margin-top: 22px;
margin-bottom: 18px;
}

.home-last-sec-para2{
font-size: 15px;
line-height: 24px; /* 150% */
margin-top: 22px;
}

.home-last-sec-ultext {
  margin-top: 20px;
  padding-left: 0px;
}

.home-last-sec-ultext li {
font-size: 15px;
line-height: 24px; /* 150% */
margin-top: 6px;
}

.home-last-sec-ultext li span{
font-size: 15px;
line-height: 24px;
}































.real-client-text1 h1{

font-size: 30px;
line-height: 40px; /* 100% */
}

.real-client-row-div1{
margin-top: 26px;
row-gap: 20px;
}

.real-client-col-div11{
border-radius: 15px;
/* border: 1.062px solid #A0A0A0; */
padding: 18px;
background-image: url(../Image/Group\ 1000008533.png);
background-repeat: no-repeat;
    background-size: cover;

}

.real-client-week-div11{
display: flex;
justify-content: space-between;
}

.real-client-week-div11 div:last-child{
display: flex;
justify-content: center;
}


.real-client-week-div11 img{
  width: 65%;
  max-height: 45px;
  margin-top: 10px !important;
}

.real-client-week-div11 div h2{
font-size: 22px;
line-height: 26.295px; /* 79.682% */
}

.real-client-week-div11 div ul{
padding-left: 20px !important;
}

.real-client-week-div11 div ul li{
font-size: 11px;
line-height: 18px; /* 138.61% */
}


.home-table .thead-dark th {
font-size: 10px;
line-height: normal;
text-align: center;
}

th.date-table{
  padding-right: 30px;
  text-align: left !important;
}

.home-table tbody tr td{
font-size: 10px;
line-height: normal;
}

.home-real-client-para112{
font-size: 14px;
line-height: 21px; /* 138.61% */
margin-top: 20px;
}

































.audit-design-div1 {
  padding-top: 35px;
  padding-bottom: 45px;
}

.audit-design-text1 {
  font-size: 34px;
  line-height: 40px; /* 118.519% */
}

.audit-design-text2 {
  font-size: 20px;
  line-height: 29px; /* 130.769% */
  margin-top: 18px;
}

.audit-design-icondiv11 {
  margin-top: 40px;
}

.audit-design-icondiv3 {
  display: flex;
  justify-content: center;
  min-height: 80px;
  align-items: end;
}

.audit-design-text3 {
  font-size: 18px;
  line-height: 28px; /* 166.667% */
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 42px;
  margin-top: 16px;
}






























.home-faqs-div1{
padding-top: 60px;
padding-bottom: 60px;
}

.home-faqs-text{
font-size: 32px;
line-height: 36px; /* 76.316% */
}


.home-faq-div2 {
  margin-top: 30px;
}

.faq-div1 {
box-shadow: 0px 4px 14px 0px #FFE8CB;
  padding: 18px 16px;
}

.faq-question-div1 {
  justify-content: space-between;
  column-gap: 20px;
}

.faq-text1 {
font-size: 14px;
line-height: 23px; /* 145.073% */
}

.faq-text1:hover {
  cursor: pointer;
}

.faq-question-div1 svg {
  cursor: pointer;
}

.faq-answer-div1 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.faq-text2 {
  font-size: 14px;
  line-height: normal;
}






























.home-zoom-div1{
  padding-top: 60px;
  padding-bottom: 50px;
}

.home-zoom-text11{
padding: 22px;
border-radius: 5px;
column-gap: 20px;
max-width: 360px;
}

.home-zoom-text11 p{
font-size: 14px;
line-height: 20px; /* 125% */
margin-bottom: 0px !important;
}

.home-zoom-text22{
column-gap: 20px;
margin-left: 0px;
margin-top: 20px;
padding: 20px;
max-width: 370px;

}

.home-zoom-text22 p{
font-size: 14px;
line-height: 20px; /* 125% */
margin-bottom: 0px !important;
}

.home-zoom-text33 {
  text-align: center;
  margin-top: 60px;
}

.home-zoom-text33 h1{
font-size: 32px;
line-height: 40px; /* 160% */
margin-top: 26px;
}

.home-zoom-text33 p{
font-size: 16px;
line-height: 26px; /* 150% */
}











}