/* .navbar-main-div1{
    animation: show 0.2s linear;
}

.navbar-main-div2{
    display: none;
    animation: hide 1s forwards;
}

@keyframes show {
    0%{
        opacity: 1;
    }
    100%{
        opacity:  1;
    }
    
} */



.navbar2-div{
background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, #1C2227 46%, #6F6F6F 100%);
stroke-width: 3px;
stroke: #818181;
filter: drop-shadow(-1px 3px 9.4px rgba(0, 0, 0, 0.25));
padding: 8px;
display: flex;
justify-content: space-between;
border-radius: 100px;
position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);

    z-index: 999999999;
    transition: 0.5s ease-in-out;
}





.last-navbar2-div{
background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(180deg, #1C2227 46%, #6F6F6F 100%);
stroke-width: 3px;
stroke: #818181;
filter: drop-shadow(-1px 3px 9.4px rgba(0, 0, 0, 0.25));
padding: 8px;
display: flex;
justify-content: space-between;
border-radius: 100px;
position: relative;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);

    z-index: 999999999;
    transition: 0.5s ease-in-out;
}



.nav2-logo1{
width: 102.884px;
height: 60px;
flex-shrink: 0;
background-color: #2C2C2C;
filter: drop-shadow(-1px 3px 9.4px rgba(0, 0, 0, 0.25));
display: flex;
align-items: center;
justify-content: center;
border-radius: 100px;

}




.navbar22-text-div1{
    display: flex;
justify-content: center;
align-items: center;
margin: 0px 30px;
}

.navbar22-text-div1 ul{
flex-direction: row;
justify-content: center;
align-items: center;
display: flex;
/* column-gap: 20px; */
}


.navbar22-text-div1 ul li{
    padding:0px 30px ;
height: 60px;
border-radius: 80px;
display: flex;
align-items: center;
width: max-content;
}


.navbar22-text-div1 ul li:hover{
background: #FFF;
filter: drop-shadow(-1px 3px 9.4px rgba(0, 0, 0, 0.25));
}



.navbar22-text-div1 ul li:hover a{
    color: #0E1215;

}




.navbar22-text1{
    color: #CFCFCF;
font-family: Helvetica;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 23.212px; /* 128.954% */
margin-bottom: 0px;
flex-shrink: 0;
border-radius: 80px;
display: flex;
align-items: center;
justify-content: center;
}



.navbar22-text1:hover{
    color: #0E1215;
font-family: Helvetica;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 23.212px; /* 128.954% */
}







.nav2-btn11{

}

.nav2-btn11 div{
    background: linear-gradient(90deg, #F90 0%, #FDB24D 100%);
    filter: drop-shadow(-1px 3px 9.4px rgba(0, 0, 0, 0.25));
width: 210px;
height: 60px;
flex-shrink: 0;
display: flex;
align-items: center;
justify-content: center;
color: #020202;
font-family: Helvetica;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 23.212px; /* 128.954% */
margin-bottom: 0px;
border-radius: 100px;
box-shadow: 0 4px 8px 2px rgba(0, 0, 0, .27), 0 10px 20px 1px rgba(0, 0, 0, .2), inset 0 2px 1px rgba(255, 255, 255, .5)
}















@media screen and (max-width: 576px) {
    
}
